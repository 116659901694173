import React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { cannonStyled } from '../theme';
import { useTableOrMobile } from '../hooks';
import { CannonBreadcrumbs } from '../atoms';
import { FAQBanner, FAQBody } from '../components/sections';

//Query
const query = graphql`
  query {
    strapiFaq {
      title
      faqSection {
        id
        title
        iconButton {
          url
        }
        iconActive {
          url
        }
        faqQuestion {
          id
          question
          answerBody
          mediaURL
        }
      }
    }
  }
`;

//Interfaces
export interface IFAQQuestion {
  id: number;
  question: string;
  answerBody: string;
  mediaURL: string;
}

export interface IFAQSection {
  id: string;
  title: string;
  iconButton: {
    url: string;
  };
  iconActive: {
    url: string;
  };
  faqQuestion: Array<IFAQQuestion>;
}

export interface IFAQ {
  strapiFaq: {
    id: number;
    title: string;
    faqSection: Array<IFAQSection>;
  };
}

//Styled Compontents

const ContainerBreadCrumbs = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? 0 : '30px',
    left: props.isMobile ? '5px' : '80px',
    width: '100%',
    textAlign: 'left',
    backgroundColor: '#F4F6FB',
  })
);

const MainWrapper = cannonStyled(Wrapper, () => ({
  display: 'flex',
  justifyContent: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflowX: 'hidden',
  textAlign: 'center',
  backgroundColor: 'none',
}));

const FAQ: React.FC<PageProps> = (props: PageProps) => {
  //Hooks
  const data = useStaticQuery<IFAQ>(query);
  const [isMobile, isTablet] = useTableOrMobile();

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper id='top' noScroll>
        <ContainerBreadCrumbs isMobile={isMobile} role='navigation' aria-label='lost combination assistance'>
          <CannonBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Support', href: '' },
              { title: `FAQ's`, href: `/faq` },
            ]}
          />
        </ContainerBreadCrumbs>
        <FAQBanner data={data} />
      </MainWrapper>
    </LayoutProvider>
  );
};

export default FAQ;
